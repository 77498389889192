<template>
  <div>
    <a-divider orientation="right">
      <div>
        <label>服务包：</label>
        <a-select default-value="add" v-model="packageId" style="width: 120px" @change="handlePackageChange">
          <a-select-option v-for="(option, index) in infoList" :key="index" :value="option.id">{{ option.title }}
          </a-select-option>
          <a-select-option value="add">添加</a-select-option>
        </a-select>
      </div>
    </a-divider>
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '服务名称不能为空' }],
            initialValue: title
          },
        ]" placeholder="展示给用户的服务名称" />
      </a-form-item>
      <a-form-item label="周期范围(周)" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-col :span="4">
          <a-input-number v-model="minCycle" :min="min_service_cycle_range" :max="max_service_cycle_range" />
        </a-col>
        <a-col :span="12">
          <a-slider style="marginLeft: 16px" v-decorator="[
            'cycleRange',
            {
              rules: [{ required: true, message: '需要选择服务周期' }],
              initialValue: cycleRange
            }
          ]" range @change="cycleRangeChange" :min="min_service_cycle_range" :max="max_service_cycle_range" />
        </a-col>
        <a-col :span="4">
          <a-input-number v-model="maxCycle" :min="min_service_cycle_range" :max="max_service_cycle_range"
            style="marginLeft: 16px" />
        </a-col>
      </a-form-item>
      <a-form-item label="授课日" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox-group v-decorator="[
          'week_day',
          {
            rules: [{ required: true, message: '需要设置授课日' }],
            initialValue: week_day
          }
        ]" style="width: 100%;">
          <a-row>
            <a-col :span="8">
              <a-checkbox value="saturday">
                周六
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="sunday">
                周日
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="monday">
                周一
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="tuesday">
                周二
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="wednesday">
                周三
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="thursday">
                周四
              </a-checkbox>
            </a-col>
            <a-col :span="8">
              <a-checkbox value="friday">
                周五
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="授课时间范围(小时)" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-col :span="4">
          <a-input-number v-model="minDay" :min="min_service_day_range" :max="max_service_day_range" />
        </a-col>
        <a-col :span="12">
          <a-slider style="marginLeft: 16px" v-decorator="[
            'dayRange',
            {
              rules: [{ required: true, message: '需要设置授课时间范围' }],
              initialValue: dayRange
            }
          ]" range @change="dayRangeChange" :min="min_service_day_range" :max="max_service_day_range" />
        </a-col>
        <a-col :span="4">
          <a-input-number v-model="maxDay" :min="min_service_day_range" :max="max_service_day_range"
            style="marginLeft: 16px" />
        </a-col>
      </a-form-item>
      <!-- 结束时间 -->
      <a-form-item label="有效期至" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-date-picker @change="endTimeOnChange" :disabled-date="disabledDate" v-decorator="[
          'end_time',
          {
            rules: [{ required: true, message: '需要设置该服务有效期' }],
            initialValue: end_time
          }
        ]" />
      </a-form-item>
      <!-- 每小时金额 -->
      <a-form-item label="金额/每小时" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number :min="min_service_price_range" :max="max_service_price_range" :step="0.1" v-decorator="[
          'price',
          {
            rules: [{ required: true, message: '需要设置每小时价格' }],
            initialValue: price
          }
        ]" />
        <span>（{{ min_service_price_range }}~{{ max_service_price_range }}）</span>
      </a-form-item>
      <a-form-item label="描述信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="4" maxlength="255" placeholder="该家教服务描述(255个字)" v-decorator="[
          'desc',
          {
            initialValue: desc
          }
        ]" />
      </a-form-item>
      <a-form-item label="上下架" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-switch v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: status
          }
        ]" />
      </a-form-item>
      <a-form-item label="服务信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'agreement',
          {
            rules: [{ required: true, message: '你需要阅读并勾选服务协议' }],
            valuePropName: 'checked'
          }
        ]">
          我已阅读并同意
          <a href="">
            服务协议
          </a>
        </a-checkbox>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-popconfirm v-if="deleteBtn" placement="rightBottom" title="确定要删除该服务包吗？" @confirm="handleDelStudent">
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a-button :loading="delLoading" type="danger" style="margin-left: 8px">删除</a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { servicePackList, servicePackAdditional, servicePackCreate, ServicePackSave, servicePackDelete } from '@/api/service_pack'
import { Slider as ASlider } from 'ant-design-vue'
import moment from 'moment'

export default {
  name: 'ServicePack',
  components: {
    ASlider
  },
  props: {
    relid: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      packageId: 'add',
      form: this.$form.createForm(this),
      submitLoading: false,
      delLoading: false,
      info: {},
      student_stage: [],
      coach_subject: [],
      deleteBtn: false,
      // 后端设置的服务周期范围
      service_cycle_range: [],
      min_service_cycle_range: 1,
      max_service_cycle_range: 10,
      // 后端设置的每日服务时间范围
      service_day_range: [],
      min_service_day_range: 8,
      max_service_day_range: 21,
      // 后端设置的每小时收费价格范围
      service_price_range: [],
      min_service_price_range: 55,
      max_service_price_range: 300,

      // 表单上的值
      title: '',
      price: '',
      cycleRange: [2, 6],
      dayRange: [12, 20],
      week_day: ['saturday', 'sunday'],
      desc: '',
      status: false,
      end_time: null,

      infoList: [],
      minCycle: 2,
      maxCycle: 6,

      minDay: 12,
      maxDay: 20
    }
  },
  created() {
  },
  mounted() {
    if (this.relid !== undefined) {
      this.loadingData(this.relid)
    }
    this.loadingAdditional()
  },
  watch: {
    service_cycle_range(val) {
      this.min_service_cycle_range = val[0]
      this.max_service_cycle_range = val[1]
    },
    service_day_range(val) {
      this.min_service_day_range = val[0]
      this.max_service_day_range = val[1]
    },
    service_price_range(val) {
      this.min_service_price_range = val[0]
      this.max_service_price_range = val[1]
    },
    cycleRange(val) {
      this.minCycle = val[0]
      this.maxCycle = val[1]
    },
    minCycle(val) {
      val = (val <= this.maxCycle) ? val : this.maxCycle
      this.cycleRange = [val, this.maxCycle]
    },
    maxCycle(val) {
      val = (val >= this.minCycle) ? val : this.minCycle
      this.cycleRange = [this.minCycle, val]
    },
    dayRange(val) {
      this.minDay = val[0]
      this.maxDay = val[1]
    },
    minDay(val) {
      val = (val <= this.maxDay) ? val : this.maxDay
      this.dayRange = [val, this.maxDay]
    },
    maxDay(val) {
      val = (val >= this.minDay) ? val : this.minDay
      this.dayRange = [this.minDay, val]
    },
    info: {
      // 切换服务包时修改表单显示
      handler: function (val, oldval) {
        this.title = val.title
        this.cycleRange = [val.min_cycle, val.max_cycle]
        this.dayRange = [val.min_day, val.max_day]
        if (val.week_day !== '') {
          this.week_day = val.week_day.split(',')
        } else {
          this.week_day = []
        }
        this.price = val.price
        this.desc = val.desc
        if (val.status === 1) {
          this.status = true
        } else {
          this.status = false
        }
        if (val.end_time) {
          this.end_time = this.timestampToMoment(val.end_time)
        } else {
          this.end_time = null
        }
      },
      deep: true
    }
  },
  methods: {
    moment,
    cycleRangeChange(e) {
      this.cycleRange = e
    },
    dayRangeChange(e) {
      this.dayRange = e
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day')
    },
    endTimeOnChange(date, dateString) {
      console.log(date, dateString)
    },
    handlePackageChange(e) {
      console.log(e)
      // 切换学员选择器，修改显示数据
      if (e === 'add') {
        // 给定初始值
        this.info = {
          title: '',
          desc: '',
          end_time: null,
          id: 'add',
          min_cycle: 2,
          max_cycle: 6,
          min_day: 12,
          max_day: 20,
          min_hours: 2,
          max_hours: 6,
          price: '',
          status: 0,
          week_day: 'saturday,sunday'
        }
        this.deleteBtn = false
      } else {
        const _this = this
        this.infoList.forEach(function (info, index) {
          if (info.id === e) {
            // 处理info为数组
            _this.info = info
            _this.deleteBtn = true
          }
        })
      }
    },
    loadingData(relid) {
      // 获取该用户服务包数据
      new Promise((resolve, reject) => {
        servicePackList({ relid: relid }).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.infoList = data.list.data
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    },
    loadingAdditional() {
      new Promise((resolve, reject) => {
        servicePackAdditional().then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.service_cycle_range = data.service_cycle_range
        this.service_day_range = data.service_day_range
        this.service_hours_range = data.service_hours_range
        this.service_price_range = data.service_price_range
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    },
    handleDelStudent(e) {
      // 删除
      const packageId = this.packageId
      if (packageId !== '' && packageId !== 'add') {
        new Promise((resolve, reject) => {
          this.delLoading = true
          servicePackDelete({ id: packageId }).then(response => {
            if (response.status === 'success') {
              resolve(response)
            } else {
              reject(response)
            }
          })
        }).then(res => {
          this.$notification.success({
            message: res.status,
            description: res.message
          })
          // 发射事件给父组件的监听属性
          this.$emit('refreshData')
        }).catch(error => {
          this.$notification.error({
            message: '错误',
            description: error.message
          })
        }).finally(() => {
          this.delLoading = false
        })
      }
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {}
          params.title = values.title
          params.price = values.price
          params.status = values.status ? 1 : 0
          params.desc = values.desc
          params.min_cycle = values.cycleRange[0]
          params.max_cycle = values.cycleRange[1]
          params.week_day = values.week_day.join(',')
          params.min_day = values.dayRange[0]
          params.max_day = values.dayRange[1]
          params.end_time = values.end_time.unix()
          if (this.packageId === 'add') {
            params.relid = this.relid
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              servicePackCreate(params).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 发射事件给父组件的监听属性
              this.$emit('refreshData')
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            params.id = this.packageId
            new Promise((resolve, reject) => {
              this.submitLoading = true
              ServicePackSave(params).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    }
  }
}
</script>
