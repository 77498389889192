
<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="true">
    <div v-if="!exist">
      <result type="info" title="未提交申请单" description="该用户还未提交审核"></result>
    </div>
    <div v-if="exist">
      <div>
        <a-row>
          <a-col span="8">
            <label>修改时间：<span v-if="update_time">{{ update_time | dayjs }}</span><span v-else>无</span></label>
          </a-col>
          <a-col span="8" style="text-align:center">
            <label>审核状态：
              <a-badge :status="applicationStatus | statusTypeFilter" :text="applicationStatus | statusFilter" />
            </label>
          </a-col>
          <a-col span="8"><label style="float:right">上次登录时间：<span v-if="teacher_info.last_login_time">{{
            teacher_info.last_login_time | dayjs
          }}</span><span v-else>无</span></label></a-col>
        </a-row>
      </div>
      <a-divider style="margin: 16px 0" orientation="left">基本信息</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item term=""><img style="width:100px;height:100px" src="@/assets/orange.png" alt="头像">
        </detail-list-item>
        <detail-list-item term="姓名" v-if="real_name_info">{{ real_name_info.auth_real_name + '—' +
          teacher_info.nick_name
        }}
          <a-icon :v-if="real_name_info.status === 1" style="color: green;" type="check" />
        </detail-list-item>
        <detail-list-item term="姓名" v-else>还未实名认证</detail-list-item>
        <detail-list-item term="性别">{{ teacher_apply_info.sex }}</detail-list-item>
        <detail-list-item term="年龄">{{ teacher_apply_info.age }}</detail-list-item>
        <detail-list-item term="籍贯">{{ teacher_apply_info.province + ' ' + teacher_apply_info.city }}</detail-list-item>
        <detail-list-item term="身份证号" v-if="real_name_info">{{ real_name_info.auth_card_number }}</detail-list-item>
        <detail-list-item term="身份证号" v-else>还未实名认证</detail-list-item>
        <detail-list-item term="联系地址">{{ teacher_apply_info.address }}</detail-list-item>
        <detail-list-item term="目前身份">{{ teacher_apply_info.current_status }}</detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">学历</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item term="就读院校">{{ teacher_apply_info.school }}</detail-list-item>
        <detail-list-item term="所学专业">{{ teacher_apply_info.major }}</detail-list-item>
        <detail-list-item term="最高学历">{{ teacher_apply_info.highest_edu }}</detail-list-item>
        <detail-list-item term="高中母校">{{ teacher_apply_info.high_school }}</detail-list-item>
        <detail-list-item term="学生证件">
          <img style="height: 100px" v-if="studentCertfileImg" :src="studentCertfileImg" alt="">
          <span v-else>无</span>
        </detail-list-item>
        <detail-list-item term="毕业证">
          <img style="height: 100px" v-if="diplomaCertfileImg" :src="diplomaCertfileImg" alt="">
          <span v-else>无</span>
        </detail-list-item>
        <detail-list-item term="学位证">
          <img style="height: 100px" v-if="degreedCertfileImg" :src="degreedCertfileImg" alt="">
          <span v-else>无</span>
        </detail-list-item>
        <detail-list-item term="教师资格证">
          <img style="height: 100px" v-if="qualificationCertfileImg" :src="qualificationCertfileImg" alt="">
          <span v-else>无</span>
        </detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">获得证书</a-divider>
      <!-- 证书信息 -->
      <div class="certificate-info">
        <div class="list">
          <div class="item" v-for="certificate in certificate_list_info" :key="certificate.resource_id">
            <img :src="certificate.url" alt="">
            <div class="card-title">{{ certificate.original_info.name }}</div>
          </div>
        </div>
      </div>
      <a-divider style="margin: 16px 0" orientation="left">授课范围</a-divider>
      <detail-list size="small" :col="1" class="detail-layout">
        <detail-list-item term="授课科目">{{ teacher_apply_info.teaching_subjects_str }}</detail-list-item>
        <detail-list-item term="辅导方式">{{ teacher_apply_info.coaching_methods }}</detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">授课区域</a-divider>
      <detail-list size="small" :col="1" class="detail-layout">
        <detail-list-item term="描述">{{ teacher_apply_info.teaching_area }}</detail-list-item>
        <detail-list-item term="地图">
          <custom-map v-if="!loading" :mapEdit="mapEdit" :city="city" :address="address" :polygon="map_polygon"
            :circle="map_circle" @overwriteData="mapDataHandle" />
        </detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">自我评价</a-divider>
      <div class="detail-layout">
        <p>{{ teacher_apply_info.self_evaluation }}</p>
      </div>
      <a-divider style="margin: 16px 0" orientation="left">视频介绍</a-divider>
      <div class="detail-layout">
        <video width="320" height="240" controls="controls">
          <source v-if="teaching_video_url" :src="teaching_video_url" type="video/mp4" />
          <!-- <source src="movie.ogg" type="video/ogg" />
          <source src="movie.webm" type="video/webm" />
          <object data="movie.mp4" width="320" height="240">
            <embed src="movie.swf" width="320" height="240" />
          </object> -->
        </video>
      </div>
      <a-divider style="margin: 16px 0" orientation="left">联系方式</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item term="微信号">{{ teacher_info.wx }}
          <a-icon v-if="teacher_info.wx_auth_status" style="color: green;" type="check" />
        </detail-list-item>
        <detail-list-item term="电话">{{ teacher_info.phone }}
          <a-icon v-if="teacher_info.phone_auth_status" style="color: green;" type="check" />
        </detail-list-item>
        <detail-list-item term="邮箱">{{ teacher_info.email }}
          <a-icon v-if="teacher_info.email_auth_status" style="color: green;" type="check" />
        </detail-list-item>
        <detail-list-item term="QQ">{{ teacher_info.qq }}</detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">审核意见</a-divider>
      <div class="detail-layout">
        <a-textarea name="" id="" v-model="reply" cols="30" rows="5"></a-textarea>
      </div>
      <a-divider />
      <div style="text-align: center">
        <a-button type="primary" :loading="state.approved" @click="ApprovedHandle">审核通过</a-button>
        <a-button style="margin-left: 8px" :loading="state.auditfail" @click="AuditFailedHandle">审核失败</a-button>
      </div>
    </div>
  </a-card>
</template>

<script>
import { teacherInfoDetail, teacherInfoApproved, teacherInfoAuditFailed } from '@/api/teacher'
import DetailList from '@/components/tools/DetailList'
import CustomMap from '@/components/Map/CustomMap'
import { Result } from '@/components'

const DetailListItem = DetailList.Item

const statusMap = {
  '0': {
    status: 'warning',
    text: '待审核'
  },
  '1': {
    status: 'success',
    text: '通过'
  },
  '2': {
    status: 'error',
    text: '未通过'
  }
}

export default {
  name: 'TeacherResume',
  components: {
    DetailList,
    DetailListItem,
    CustomMap,
    Result
  },
  props: {
    id: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      loading: true,
      state: {
        approved: false,
        auditfail: false
      },
      exist: true,
      teacher_apply_info: {},
      reply: '',
      teacher_info: {},
      real_name_info: {},
      certificate_arr: [],
      applicationStatus: 0,
      update_time: 0,
      // 地图
      mapEdit: true,
      map_circle: [],
      map_polygon: [],
      city: '',
      address: '',
      // 视频链接
      teaching_video_url: '',
      // 获得证书展示
      certificate_list_info: [],
      // 证书相关
      studentCertfileImg: '',
      diplomaCertfileImg: '',
      degreedCertfileImg: '',
      qualificationCertfileImg: ''
    }
  },
  created() {
    if (this.id !== undefined) {
      this.loadInfo(this.id)
    } else {
      this.exist = true
    }
  },
  mounted() {
  },
  watch: {
    teacher_apply_info(val) {
      this.applicationStatus = val.status
      this.update_time = val.update_time
      this.reply = val.reply
      // 获得证书
      this.certificate_list_info = val.certificate_list_info
      this.teaching_video_url = val.teaching_video_info.url
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    loadInfo(id) {
      const _this = this
      this.loading = true
      // 获取该用户服务包数据
      new Promise((resolve, reject) => {
        teacherInfoDetail({ id: id }).then(response => {
          _this.loading = false
          if (response.status === 'success') {
            var data = response.data
            const teacherApplyInfo = data.teacher_apply_info
            this.teacher_apply_info = teacherApplyInfo
            this.teacher_info = teacherApplyInfo.teacher
            this.certificate_arr = teacherApplyInfo.certificate_arr
            this.real_name_info = data.real_name_info
            // 地图数据
            var province = teacherApplyInfo.province
            if (province === '重庆市' || province === '北京市' || province === '天津市') {
              this.city = province
            } else {
              this.city = teacherApplyInfo.city
            }
            this.map_circle = teacherApplyInfo.teaching_area_map_arr.circle
            this.map_polygon = teacherApplyInfo.teaching_area_map_arr.polygon
            this.address = teacherApplyInfo.address
            resolve(data.teacher_apply_info)
          } else {
            this.$notification.error({
              message: '系统繁忙，请刷新重试',
              description: ''
            })
          }
        })
      }).then(teacherInfo => {
        // 加载学生证
        return this.syncStudentCertImage(teacherInfo)
      }).then(teacherInfo => {
        // 加载毕业证
        return this.syncdiplomaCertImage(teacherInfo)
      }).then(teacherInfo => {
        // 加载学位证
        return this.syncdegreedCertImage(teacherInfo)
      }).then(teacherInfo => {
        // 加载教师资格证
        return this.syncqualificationCertImage(teacherInfo)
      })
    },
    // 异步加载学生证图片（原因：受到保护的，需要携带token获取）
    syncStudentCertImage(teacherInfo) {
      const winThis = this
      var p = new Promise(function (resolve, reject) {
        const studentCertImgInfo = teacherInfo.student_cert_img_info
        // 存在
        if (Object.keys(studentCertImgInfo).length !== 0) {
          const url = studentCertImgInfo.url
          winThis.$http({
            url: url,
            method: 'get',
            params: {
            }
          }).then(res => {
            winThis.studentCertfileImg = res
          })
        }

        resolve(teacherInfo)
      })
      return p
    },
    // 毕业证
    syncdiplomaCertImage(teacherInfo) {
      const winThis = this
      var p = new Promise(function (resolve, reject) {
        const diplomaCertImgInfo = teacherInfo.diploma_cert_img_info
        // 存在
        if (Object.keys(diplomaCertImgInfo).length !== 0) {
          const url = diplomaCertImgInfo.url
          winThis.$http({
            url: url,
            method: 'get',
            params: {
            }
          }).then(res => {
            winThis.diplomaCertfileImg = res
          })
        }

        resolve(teacherInfo)
      })
      return p
    },
    // 学位证
    syncdegreedCertImage(teacherInfo) {
      const winThis = this
      var p = new Promise(function (resolve, reject) {
        const degreeCertImgInfo = teacherInfo.degree_cert_img_info
        // 存在
        if (Object.keys(degreeCertImgInfo).length !== 0) {
          const url = degreeCertImgInfo.url
          winThis.$http({
            url: url,
            method: 'get',
            params: {
            }
          }).then(res => {
            winThis.degreedCertfileImg = res
          })
        }

        resolve(teacherInfo)
      })
      return p
    },
    // 教师资格证
    syncqualificationCertImage(teacherInfo) {
      const winThis = this
      var p = new Promise(function (resolve, reject) {
        const teacherQualificationCertInfo = teacherInfo.teacher_qualification_cert_info
        // 存在
        if (Object.keys(teacherQualificationCertInfo).length !== 0) {
          const url = teacherQualificationCertInfo.url
          winThis.$http({
            url: url,
            method: 'get',
            params: {
            }
          }).then(res => {
            winThis.qualificationCertfileImg = res
          })
        }

        resolve(teacherInfo)
      })
      return p
    },
    // 地图数据，存储到数据库中。前台使用
    mapDataHandle(e) {
    },
    // 审核通过
    ApprovedHandle() {
      this.state.approved = true
      new Promise((resolve, reject) => {
        teacherInfoApproved({ id: this.id, reply: this.reply }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message
        })
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      }).finally(() => {
        this.state.approved = false
      })
    },
    // 审核失败
    AuditFailedHandle() {
      this.state.approved = true
      new Promise((resolve, reject) => {
        teacherInfoAuditFailed({ id: this.id, reply: this.reply }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message
        })
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      }).finally(() => {
        this.state.approved = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .status-list {
      text-align: left;
    }
  }

  .certificate-info {
    margin-top: 30px;
    padding-left: 50px;
    overflow hidden

    .list {
      width: 640px;
      display: inline-block;

      .item {
        width: 200px;
        float: left;

        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 10px;

        img {
          width: 200px;
          height: 140px;
          border-radius: 10px;
        }

        .card-title {
          text-align: left;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 0px;
          color: #333333;

          margin-top: 5px;
        }
      }
    }

  }
</style>
