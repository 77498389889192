<template>
  <a-card :bordered="false">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>
  </a-card>
</template>
<script>
import { DatePicker as ADatePicker } from 'ant-design-vue'
import { systemActiveLog } from '@/api/system'
import moment from 'moment'

const statusMap = {
  'Active': {
    status: 'success',
    text: '激活'
  },
  'Closed': {
    status: 'default',
    text: '关闭'
  },
  'Error': {
    status: 'error',
    text: '异常'
  }
}

const dateConfig = {
  rules: [{ type: 'object', required: true, message: 'Please select time!' }]
}

const columns = [
  {
    sorter: true,
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '用户类型',
    dataIndex: 'user'
  },
  {
    title: '用户ID',
    dataIndex: 'userid'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: 'IP',
    dataIndex: 'ipaddress'
  },
  {
    title: '时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  }
]

export default {
  name: 'TeacherActiveLog',
  props: {
    teacherId: {
      type: Number,
      default: undefined
    }
  },
  components: {
    ADatePicker
  },
  data() {
    return {
      description: '系统活动日志',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      dateConfig: dateConfig,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {},
      dateFormat: 'YYYY-MM-DD',
      date: '',
      queryDate: null,
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false
    }
  },
  created() {
  },
  mounted() {
    this.queryParam = { userid: this.teacherId, user: 'teacher' }
    this.fetch(this.queryParam)
  },
  watch: {
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.queryParam
      const params = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      }
      this.fetch(params)
    },
    fetch(params) {
      console.log(params)
      this.loading = true
      new Promise((resolve, reject) => {
        systemActiveLog(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'ClientsDetail', query: { id: record.id } })
    }
  }
}
</script>
