<template>
  <div>
    <div :style="{ width: mapWidth, height: mapHeight }" id="map-container"></div>
    <div v-if="mapEdit" class="map-edit-area">
      <div class="select-shape">
        <a-radio-group name="map_func" @change="paintTypeChange" default-value="" :value="mapShapeValue">
          <a-radio value="polygon">矩形</a-radio>
          <a-radio value="circle">圆形</a-radio>
        </a-radio-group>
        <span style="color:#fa750d;">※</span>
        <span style="margin-left: 5px;">操作说明：矩形通过在地图上点击来绘制(双击结束)，圆形通过拖拽来绘制</span>
      </div>
      <div class="operation-area">
        <a-button :disabled="drawingStatus" @click="mapToolClose" type="primary">关闭绘图</a-button>
        <a-button style="margin-left: 8px" @click="mapClear">清除</a-button>
      </div>
    </div>
  </div>
</template>

<script>
// 引入高德地图
import AMap from 'AMap'

export default {
  name: 'CustomMap',
  props: {
    mapEdit: {
      type: Boolean,
      default: false
    },
    prefixCls: {
      type: String,
      default: 'antd-pro-components-article-list-content-index-listContent'
    },
    polygon: {
      type: Array,
      default: () => {
        return [
        ]
      }
    },
    circle: {
      type: Array,
      default: () => {
        return [
        ]
      }
    },
    city: {
      type: String,
      default: '重庆'
    },
    address: {
      type: String,
      default: '朝天门码头'
    },
    mapWidth: {
      type: String,
      default: '100%'
    },
    mapHeight: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      // 是否关闭绘图
      drawingStatus: true,
      map: {},
      lng: 116.397428, // 经度
      lat: 39.90923, // 纬度

      overlays: [], // 初始化绘画内容为空
      mouseTool: {},

      mapShapeValue: '',
      coverStyle: {
        // 地图覆盖区样式
        strokeOpacity: 0.8,
        strokeColor: '#80d8ff',
        fillColor: '#00b0ff',
        fillOpacity: 0.35
      }
    }
  },
  created() {
  },
  mounted() {
    // 初始化地图
    this.initMap(this.lng, this.lat)
    // 设置城市与地址
    this.findPlace(this.city, this.address)
  },
  computed: {
    changeAddress() {
      const { city, address } = this
      return {
        city,
        address
      }
    },
    changeCoordinate() {
      const { lng, lat } = this
      return {
        lng,
        lat
      }
    }
  },
  watch: {
    mapEdit(val) {
    },
    lng(val) {
    },
    lat(val) {
    },
    changeAddress(val) {
      // 地址变化，查找新坐标并赋值
      this.findPlace(val.city, val.address)
    },
    changeCoordinate(val) {
      // 坐标变化
      // 显示以用户为中心的地图
      this.initMap(val.lng, val.lat)
      // 在地图上标记该点
      var marker = new AMap.Marker({
        position: [val.lng, val.lat]
      })
      this.map.add(marker)
      // 在地图上标多边形覆盖
      if (this.polygon) {
        this.addPolygon(this.map, this.polygon)
      }
      // 在地图上标记圆形覆盖
      if (this.circle) {
        this.addCycle(this.map, this.circle)
      }
      // 添加工具
      this.addMapTool(this.map, this.mapEdit)
    },
    polygon(val) {
    },
    circle(val) {
    },
    // 保存的值
    overlays(val) {
      if (val.length > 0) {
        var data = {
          circle: [],
          polygon: []
        }
        // 组装数据
        for (var i in val) {
          var info = val[i]
          // 圆形
          if (info.CLASS_NAME === 'AMap.Circle') {
            var circle = {}
            circle.center = info.Ce.center
            circle.radius = info.Ce.radius
            data.circle.push(circle)
          }
          // 多边形
          if (info.CLASS_NAME === 'AMap.Polygon') {
            data.polygon.push(info.Ce.path)
          }
        }
        // 将数据发送到父组件
        this.$emit('overwriteData', data)
      }
    }
  },
  methods: {
    findPlace(city, address) {
      const _this = this
      AMap.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder()
        geocoder.setCity(city)
        geocoder.getLocation(address, _this.homeCallback)
      })
    },
    // 初始化地图
    initMap(lng, lat) {
      const map = new AMap.Map('map-container')
      map.setCenter([lng, lat])
      map.setZoom(12)
      this.map = map
    },
    // 添加多边形覆盖
    addPolygon(map, polygon) {
      // 添加多边形覆盖
      if (polygon.length > 0) {
        var polygonMap = []
        for (var index in polygon) {
          var point = polygon[index]
          point = this.polygonHandle(point)
          polygonMap[index] = new AMap.Polygon(this.coverStyle)
          polygonMap[index].setPath(point)
          polygonMap[index].setMap(map)
          // 将图形push到全局地图覆盖物数据中
          this.overlays.push(polygonMap[index])
        }
      }
    },
    // 处理多边形数据，用于外部数据覆盖地图
    polygonHandle(mapPoint) {
      var pointArr = []
      mapPoint.forEach((item, index, array) => {
        pointArr.push([item.lng, item.lat])
      })
      return pointArr
    },
    // 添加圆形覆盖
    addCycle(map, circles) {
      if (circles.length > 0) {
        var circleMap = []
        for (var index in circles) {
          var circle = circles[index]
          circleMap[index] = new AMap.Circle(this.coverStyle)
          circleMap[index].setCenter([circle.center.lng, circle.center.lat])
          circleMap[index].setRadius(circle.radius)
          circleMap[index].setMap(map)
          // 将图形push到全局地图覆盖物数据中
          this.overlays.push(circleMap[index])
        }
      }
    },
    // 加载地图工具
    addMapTool(map, mapEdit) {
      if (mapEdit) {
        const _this = this
        AMap.plugin(['AMap.MouseTool'], function () {
          var mouseTool = new AMap.MouseTool(map)
          _this.mouseTool = mouseTool
          // 监听draw事件可获取画好的覆盖物
          _this.mouseTool.on('draw', function (e) {
            _this.overlays.push(e.obj)
          })
        })
      }
    },
    // 解析地址，回调函数，返回marker
    homeCallback(status, result) {
      if (status === 'complete' && result.info === 'OK') {
        // 将查询到的坐标在地图上标点
        var lat = result.geocodes[0].location.lat
        var lng = result.geocodes[0].location.lng
        this.lat = lat
        this.lng = lng
      } else {
        this.$notification.error({
          message: '错误',
          description: '未能在地图上标记教员位置'
        })
      }
    },
    draw(type) {
      switch (type) {
        case 'polygon': {
          this.mouseTool.polygon({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
          })
          break
        }
        case 'circle': {
          this.mouseTool.circle({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
          })
          break
        }
      }
    },
    paintTypeChange(e) {
      this.mapShapeValue = e.target.value
      // 修改绘图方式
      if (e.target.value === 'polygon') {
        this.draw('polygon')
      } else if (e.target.value === 'circle') {
        this.draw('circle')
      }
      this.drawingStatus = false
    },
    removeMapFuncSelect() {
      this.mapShapeValue = ''
    },
    mapToolClose: function (e) {
      this.removeMapFuncSelect()
      // 关闭
      this.mouseTool.close(true)
      this.drawingStatus = true
    },
    mapClear: function (e) {
      // 在地图上移除图形
      this.map.remove(this.overlays)
      // 清除数据
      this.overlays = []
    }
  }
}
</script>

<style lang="stylus" scoped>

  .map-edit-area {
    margin-top: 10px;
    .select-shape {

    }

    .operation-area {
      margin-top: 5px;

      button {
        width: 100px;
        height: 36px;
      }
    }
  }
</style>
