<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-tabs default-active-key="2">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="solution" />
          资料
        </span>
        <teacher-profile :id="id" :info="info" />
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="contacts" />
          审核单
        </span>
        <teacher-resume :id="id" />
      </a-tab-pane>
      <a-tab-pane key="3">
        <span slot="tab">
          <a-icon type="file-sync" />
          服务包
        </span>
        <service-pack :relid="id" />
      </a-tab-pane>
      <a-tab-pane key="4">
        <span slot="tab">
          <a-icon type="file-sync" />
          订单
        </span>
        <teacher-order :teacherId="id" />
      </a-tab-pane>
      <a-tab-pane key="5">
        <span slot="tab">
          <a-icon type="money-collect" />
          交易流水
        </span>
        Tab 3
      </a-tab-pane>
      <a-tab-pane key="6">
        <span slot="tab">
          <a-icon type="file-exclamation" />
          工单
        </span>
        Tab 3
      </a-tab-pane>
      <a-tab-pane key="7">
        <span slot="tab">
          <a-icon type="mail" />
          邮件日志
        </span>
        <teacher-email :teacherId="id" />
      </a-tab-pane>
      <a-tab-pane key="8">
        <span slot="tab">
          <a-icon type="mobile" />
          短信日志
        </span>
        <teacher-sms :teacherId="id" />
      </a-tab-pane>
      <a-tab-pane key="9">
        <span slot="tab">
          <a-icon type="paper-clip" />
          活动日志
        </span>
        <teacher-active-log :teacherId="id" />
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import { teacherInfo } from '@/api/teacher'
import TeacherProfile from './components/Profile'
import TeacherResume from './components/Resume'
import ServicePack from './components/ServicePack'
import TeacherOrder from './components/TeacherOrder'
import TeacherEmail from './components/TeacherEmail'
import TeacherSms from './components/TeacherSms'
import TeacherActiveLog from './components/TeacherActiveLog'

export default {
  name: 'AddRule',
  components: {
    TeacherProfile,
    TeacherResume,
    ServicePack,
    TeacherOrder,
    TeacherEmail,
    TeacherSms,
    TeacherActiveLog
  },
  data() {
    return {
      description: '用户详情',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      id: undefined,
      queryData: {
      },
      // form
      form: this.$form.createForm(this),
      submitLoading: false,
      info: {}
    }
  },
  created() {
    const id = parseInt(this.$route.query.id)
    if (id === undefined) {
      this.$router.back(-1)
    }
    this.loadInfo(id)
    this.id = id
  },
  mounted() {
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        teacherInfo({ id: id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.info = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
        this.$router.back(-1)
      })
    }
  }
}
</script>
